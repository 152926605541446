<template>
  <div>
    <CRow>
      <!--CCol v-for="(widget, index) of widgets" :key="index" class="col-lg-12" :class="'col-xl-' + (widget.col || 6)"-->
      <CCol v-for="(widget, index) of widgets" :key="index" :col="widget.col || 6">
        <div v-if="widget.is_component" :is="widget.component" v-bind="{ ...(widget.props || {}), config: { ...(widget.config || {}) } }" />
        <ACRUDTable v-else :items="widget.items" :fields="widget.fields" :config="widget.config" @preparedHelpers="onPreparedHelpers">
          <template #identifier>
            <td>{{ building.identifier }}</td>
          </template>
          <template #owner>
            <td>{{ building.current_owner.contact.name }}</td>
          </template>
        </ACRUDTable>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { order } from '@/app/_utils/global-utils'
import crudMixin from '@/app/views/_mixins/crud-mixin'

import ChargesTable from '@/app/views/admin/processes/charges/elements/chargesTable.vue'
import InvoicesTable from '@/app/views/admin/invoicing/invoices/elements/invoicesTable.vue'
import PaymentsTable from '@/app/views/admin/processes/payments/elements/paymentsTable.vue'
import ReceiptsTable from '@/app/views/admin/invoicing/receipts/elements/receiptsTable.vue'
import InvoiceNotesTable from '@/app/views/admin/processes/invoice_notes/elements/invoiceNotesTable.vue'
import BuildingBalanceTable from '@/app/views/admin/reports/elements/buildingBalanceTable.vue'

export default {
  name: 'BuildingDashboard',
  mixins: [crudMixin],
  components: { ChargesTable, InvoicesTable, PaymentsTable, ReceiptsTable, InvoiceNotesTable, BuildingBalanceTable },
  data() {
    return {
      lists: {},
      widgets: [],
      building: {},
      building_id: this.$route.params.id
    }
  },
  mounted() {
    this.getData()
  },
  beforeDestroy() {
    this.$store.dispatch('setHeaderBuildingId', '')
  },
  methods: {
    getData() {
      this.$store.dispatch('setHeaderBuildingId', this.building_id)
      const self = this
      self.$http
        .get(`admin/buildings/${this.building_id}/dashboard`)
        .then(response => {
          self.building = response.data.building
          self.prepareHelpers(response.data._lists || [], true)
          self.setWidgets()
        })
        .catch(error => {
          console.error(error)
        })
    },
    setWidgets() {
      const perPage = 5
      const options = { key: 'options', label: ' ', sorter: false, filter: false, _style: `text-align: center; width: 50px;` }
      const props = { building_id: this.building.id, params: { _lists: 'none' } }
      const config = { perPage, show: false, delete: false, noCreate: true, _check: false }
      const fields = [this.helpers.monthName, this.helpers.identifier, this.helpers.ownerName, this.helpers.currencyValue, options]

      const widgets = {
        charges: { name: 'charges', is_component: true, component: ChargesTable, config, props: { ...props, fields: [this.getHelperObject('imputation'), 'name', ...fields] } },
        balance: { name: 'balance', is_component: true, component: BuildingBalanceTable, config: { ...config, lastPage: true }, props: { ...props, params: { _lists: 'contact_types,building_types,property_units' } }, col: 12 },
        invoices: { name: 'invoices', is_component: true, component: InvoicesTable, config, props: { ...props, params: { _lists: 'contact_types' }, fields: [{ key: 'consecutive', label: this.$t('Invoice') }, ...fields] } },
        payments: { name: 'payments', is_component: true, component: PaymentsTable, config, props: { ...props, fields: [{ key: 'receipt_id', label: '# Receipt', helper: { id: 'receipt.consecutive' } }, ...fields.slice(0, 4), 'payment_date', options] } },
        receipts: { name: 'receipts', is_component: true, component: ReceiptsTable, config, props: { ...props, params: { _lists: 'contact_types' }, fields: [{ key: 'consecutive', label: this.$t('Receipt') }, ...fields] } },
        invoice_notes: { name: 'invoice_notes', is_component: true, component: InvoiceNotesTable, config, props: { ...props, fields: [this.getHelperObject('imputation'), ...fields.slice(0, 4), this.helpers.natureName, this.helpers.inputType, this.helpers.currencyValue, options] } },
        scheduled_charges: {
          fields: [this.getHelperObject('imputation'), 'name', ...fields], //, { key: 'options', label: ' ' }],
          config: {
            name: 'property_scheduled_charge',
            route: '/admin/processes/scheduled_charges',
            display_name: this.$t('Scheduled charges'),
            file_name: `${this.property.name} - ` + this.$t('Scheduled charges') + ` - ${this.building.identifier}`,
            url: `admin/property_scheduled_charges?month_ids=0&building_id=${this.building.id}`,
            ...{ ...config, parseItems: this.parseItems }
          }
        },
        extra_fees: {
          fields: [this.getHelperObject('imputation'), 'name', ...fields], //, { key: 'options', label: ' ' }],
          config: {
            name: 'property_extra_fee',
            route: '/admin/processes/extra_fees',
            display_name: this.$t('Extra fees'),
            file_name: `${this.property.name} - ` + this.$t('Extra fees') + ` - ${this.building.identifier}`,
            url: `admin/property_extra_fees?month_ids=0&building_id=${this.building.id}`,
            ...{ ...config, parseItems: this.parseItems }
          }
        },
        recurrent_charges: {
          fields: [this.getHelperObject('imputation'), 'name', ...fields], //, { key: 'options', label: ' ' }],
          config: {
            name: 'property_recurrent_charge',
            route: '/admin/processes/recurrent_charges',
            display_name: this.$t('Recurrent charges'),
            file_name: `${this.property.name} - ` + this.$t('Recurrent charges') + ` - ${this.building.identifier}`,
            url: `admin/property_recurrent_charges?month_ids=0&building_id=${this.building.id}`,
            ...{ ...config, parseItems: this.parseItems }
          }
        },
        emails: {
          col: 12,
          fields: [
            //
            'id',
            { key: 'entity', label: 'module' },
            'subject',
            { key: '_email_status', array: true },
            'created_at',
            'sent_at'
          ],
          config: {
            name: 'email',
            display_name: this.$t('Emails'),
            file_name: `${this.property.name} - ` + this.$t('Emails') + ` - ${this.building.identifier}`,
            url: `admin/emails?conditions=building_id=${this.building.id}`,
            ...{ ...config, parseItems: this.parseEmails }
          }
        }
      }

      //this.widgets = [widgets.scheduled_charges, widgets.extra_fees, widgets.recurrent_charges]
      this.widgets = [widgets.invoices, widgets.payments, widgets.receipts, widgets.invoice_notes, widgets.balance, widgets.charges, widgets.scheduled_charges, widgets.extra_fees, widgets.recurrent_charges, widgets.emails]
    },
    parseItems(parent, items) {
      for (let item in order(items, 'month_id').reverse()) {
        item = items[item]
        item.owner = this.building.current_owner.contact
        item.building = this.building
      }
      return items
    },
    parseEmails(parent, items) {
      for (let item in items) {
        item = items[item]
        item.subject = ''
        item.created_at = this.parsers.datetime(item._created_at)
        item._email_status = item._sent_emails.length ? [] : ['In queue']
        for (let email in item._sent_emails) {
          email = item._sent_emails[email]
          item.subject = email.subject
          item.sent_at = this.parsers.datetime(email.created_at)
          item._email_status.push(email.recipient_email + ' => ' + (email.opens ? this.$t('Sent opened') : this.$t('Sent unopened')) + (email.clicks ? ' - ' + this.$t('Clicked') : ''))
        }
      }
      return items
    }
  }
}
</script>
