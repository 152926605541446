var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ACRUDTable',{ref:"crud_table",attrs:{"fields":_vm.fields || [
      //'id',
      { key: 'receipt_id', label: '# Receipt', helper: { id: 'receipt.consecutive' } },
      _vm.helpers.inputType,
      _vm.helpers.bankAccount,
      _vm.helpers.monthName,
      { key: 'month_id', _classes: 'hide' },
      _vm.helpers.building,
      _vm.getHelperObject('building_type', 'building.building_type_id'),
      _vm.getHelperObject('property_unit', 'building.property_unit_id'),
      _vm.helpers.ownerName,
      { key: 'consecutive', label: 'Invoice', helper: { id: 'invoice.consecutive' } },
      Object.assign({}, _vm.helpers.currencyValue, {key: 'invoice_value', helper: { id: 'invoice.value' }}),
      _vm.helpers.currencyValue,
      'payment_date'
    ],"config":Object.assign({}, {url: 'admin/property_payments' + (this.building_id ? ("?conditions=building_id=" + (this.building_id)) : ''),
    route: '/admin/processes/payments',
    name: 'payment',
    lname: 'property_payments',
    params: Object.assign({}, {_with: 'receipt,building', _lists: 'months,imputations,bank_accounts,building_types,property_units'}, (_vm.params || {})),
    filters: _vm.filters ? [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'building_code', use: 'building.identifier' }, { key: 'owner_name', use: 'owner.name' }, 'bank_account'] : []},
    (_vm.config || {}))},on:{"preparedHelpers":_vm.onPreparedHelpers}})}
var staticRenderFns = []

export { render, staticRenderFns }