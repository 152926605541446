<template>
  <ACRUDTable
    ref="crud_table"
    :fields="
      fields || [
        //'id',
        { key: 'receipt_id', label: '# Receipt', helper: { id: 'receipt.consecutive' } },
        helpers.inputType,
        helpers.bankAccount,
        helpers.monthName,
        { key: 'month_id', _classes: 'hide' },
        helpers.building,
        getHelperObject('building_type', 'building.building_type_id'),
        getHelperObject('property_unit', 'building.property_unit_id'),
        helpers.ownerName,
        { key: 'consecutive', label: 'Invoice', helper: { id: 'invoice.consecutive' } },
        { ...helpers.currencyValue, key: 'invoice_value', helper: { id: 'invoice.value' } },
        helpers.currencyValue,
        'payment_date'
      ]
    "
    :config="{
      url: 'admin/property_payments' + (this.building_id ? `?conditions=building_id=${this.building_id}` : ''),
      route: '/admin/processes/payments',
      name: 'payment',
      lname: 'property_payments',
      params: { _with: 'receipt,building', _lists: 'months,imputations,bank_accounts,building_types,property_units', ...(params || {}) },
      filters: filters ? [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'building_code', use: 'building.identifier' }, { key: 'owner_name', use: 'owner.name' }, 'bank_account'] : [],
      ...(config || {})
    }"
    @preparedHelpers="onPreparedHelpers"
  />
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'paymentsTable',
  mixins: [crudMixin],
  props: ['building_id', 'fields', 'filters', 'config', 'params'],
  data() {
    return {
      period_type_id: 'REC' // TODO: Why ??? Restricted ?
    }
  },
  methods: {
    onGetData(month_ids) {
      //if (month_id) {
      //this.month = { month_id, _period_name: this.getPeriodName(month_id) }
      this.$refs.crud_table.getData(false, { month_ids }, true)
      //}
    }
  }
}
</script>
